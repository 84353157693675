// ---> sales <---
import priyankaImage from "../../../image/homePage/ourTeam/Sales/priyanka.jpg";
import alosiaImage from "../../../image/homePage/ourTeam/Sales/alosia.jpg";
import rojaImage from "../../../image/homePage/ourTeam/Sales/roja.jpg";
import virajImage from "../../../image/homePage/ourTeam/Sales/viraj.jpg";
import goldwinImage from "../../../image/homePage/ourTeam/Sales/goldwin.jpg";
import suvarnaImage from "../../../image/homePage/ourTeam/Sales/suvarna.jpg";
// ---> Design <---
import porusImage from "../../../image/homePage/ourTeam/Design/porus.jpg";
import suneelImage from "../../../image/homePage/ourTeam/Design/sunil.jpg";
import naveenImage from "../../../image/homePage/ourTeam/Design/naveen.jpg";
import likhitImage from "../../../image/homePage/ourTeam/Design/likhit.jpg";
import simranImage from "../../../image/homePage/ourTeam/Design/simran.jpg";
//  ---->Dev<---
import rahulImage from "../../../image/homePage/ourTeam/Dev/rahul.jpg";
import chetanImage from "../../../image/homePage/ourTeam/Dev/chetan.jpg";
import rohitImage from "../../../image/homePage/ourTeam/Dev/rohit.jpg";
import rishikaImage from "../../../image/homePage/ourTeam/Dev/rishika.jpg";
import sanjeevImage from "../../../image/homePage/ourTeam/Dev/sanjeev.jpg";
import govindImage from "../../../image/homePage/ourTeam/Dev/govind.jpg";
import prakashImage from "../../../image/homePage/ourTeam/Dev/prakash.jpg";
import ankitImage from "../../../image/homePage/ourTeam/Dev/ankit.jpg";
import niranjanImage from "../../../image/homePage/ourTeam/Dev/niranjan.jpg";
import kartikeyaImage from "../../../image/homePage/ourTeam/Dev/kartikeya.jpg";

import mailLogo from "../../../image/socialLogos/teamMailIcon.svg";
import linkdinLogo from "../../../image/socialLogos/teamLinkedinIcon.svg";

export const mobileCarouselData = [
  {
    row1: [
      {
        name: "Porus Rathore",
        details: (
          <>
            CEO
            <br></br>
            Leader | Serial Entrepreneur | Product Strategy Expert
          </>
        ),
        image: porusImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:porus.rathore@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/porusrathore/"
      },
      {
        name: "Priyanka Ganguly",
        details: (
          <>
            CFO
            <br></br>
            Business Development Expert
          </>
        ),
        image: priyankaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:priyanka.ganguly@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/priyankaganguly "
      }
    ],
    row2: [
      {
        name: "Alosia Rose",
        details: "Marketing Head",
        image: alosiaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:alosia.rose@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/alosiasheelarose/"
      },
      {
        name: "Chiruvolu Roja ",
        details: "Sr. Business Development Manager",
        image: rojaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:chiruvolu.roja@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/chiruvolu-roja-43b20324a"
      },
      
    ]
  },
  {
    row1: [
      {
        name: "Sunil CH",
        details: "UX/UI Designer",
        image: suneelImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:sunil.ch@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/suneel-kumar-234844220"
      },
      {
        name: "Rahul Yadav",
        details: "Sr. Software Developer",
        image: rahulImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:rahul.y@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/rahulyadav96"
      },
    ],
    row2: [
      {
        name: "Chetan Malviya",
        details: "Sr. Software Developer",
        image: chetanImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:chetan.malviya@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/devvrat02/"
      },
      {
        name: "Govind Kushwaha",
        details: "Sr. Software Developer",
        image: govindImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:govind.kushwaha@deskala.in ",
        linkdinUrl:
          "https://www.linkedin.com/in/govind-kumar-kushwaha-121433208/"
      },
      
    ]
  },
  {
    row1: [
     
      {
        name: "Rohit Singh Solanki",
        details: "Software Developer",
        image: rohitImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:rohit.solanki@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/rohit-singh-solanki-924119147/"
      },
      {
        name: "Sanjeev Sahani",
        details: "Software Developer",
        image: sanjeevImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:sanjiv.sahani@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/sanjiv-sahani-22332120a/"
      },
    ],
    row2: [
      {
        name: "Rishika Rathore",
        details: "Software Developer",
        image: rishikaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:rishika.rathore@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/rishika-rathore-rishika/"
      },
      
    
      
    ]
  },
  
];

export const teamCarouselData = [
  {
    row1: [
      {
        name: "Porus Rathore",
        details: (
          <>
            CEO
            <br></br>
            Leader | Serial Entrepreneur | Product Strategy Expert
          </>
        ),
        image: porusImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:porus.rathore@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/porusrathore/"
      },
      {
        name: "Priyanka Ganguly",
        details: (
          <>
            CFO
            <br></br>
            Business Development Expert
          </>
        ),
        image: priyankaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:priyanka.ganguly@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/priyankaganguly "
      },
      {
        name: "Alosia Rose",
        details: "Marketing Head",
        image: alosiaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:alosia.rose@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/alosiasheelarose/"
      },
      
    ],
    row2: [
      {
        name: "Chiruvolu Roja ",
        details: "Sr. Business Development Manager",
        image: rojaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:chiruvolu.roja@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/chiruvolu-roja-43b20324a"
      },

      {
        name: "Sunil CH",
        details: "UX/UI Designer",
        image: suneelImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:sunil.ch@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/suneel-kumar-234844220"
      },
      {
        name: "Rahul Yadav",
        details: "Sr. Software Developer",
        image: rahulImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:rahul.y@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/rahulyadav96"
      },
      
    ]
  },
  {
    row1: [

      {
        name: "Chetan Malviya",
        details: "Sr. Software Developer",
        image: chetanImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:chetan.malviya@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/devvrat02/"
      },
      {
        name: "Govind Kushwaha",
        details: "Sr. Software Developer",
        image: govindImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:govind.kushwaha@deskala.in ",
        linkdinUrl:
          "https://www.linkedin.com/in/govind-kumar-kushwaha-121433208/"
      },
      {
        name: "Rohit Singh Solanki",
        details: "Software Developer",
        image: rohitImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:rohit.solanki@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/rohit-singh-solanki-924119147/"
      },
      
    ],
    row2: [
      {
        name: "Sanjeev Sahani",
        details: "Software Developer",
        image: sanjeevImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:sanjiv.sahani@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/sanjiv-sahani-22332120a/"
      },
      {
        name: "Rishika Rathore",
        details: "Software Developer",
        image: rishikaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:rishika.rathore@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/rishika-rathore-rishika/"
      },
    ]
  }
];



      // {
      //   name: "Naveen L",
      //   details: (
      //     <>
      //       CDO
      //       <br></br>
      //       Product Design Expert
      //     </>
      //   ),
      //   image: naveenImage,
      //   mailImg: mailLogo,
      //   linkdinImg: linkdinLogo,
      //   mailUrl: "mailto:naveen.lingam@deskala.in",
      //   linkdinUrl: "https://www.linkedin.com/in/naveenlingam/"
      // },
      // {
      //   name: "Prakash Hemani",
      //   details: (
      //     <>
      //       CPO
      //       <br></br>
      //       Product Development Expert
      //     </>
      //   ),
      //   image: prakashImage,
      //   mailImg: mailLogo,
      //   linkdinImg: linkdinLogo,
      //   mailUrl: "mailto:prakash.hemani@deskala.in ",
      //   linkdinUrl: "https://www.linkedin.com/in/prakash-hemani-b629a588"
      // }
